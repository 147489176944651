.light {
    width: 0;
    height: 0;
    border-left: 30vw solid transparent;
    border-right: 30vw solid transparent;
    /* border-bottom: 170vh solid rgba(212, 216, 233, 0.664); */
    border-bottom: 170vh solid rgba(255, 255, 255, 0.822);
    position: absolute;
    left: -14.5vw;
    top: 3vw;
    z-index: 3;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform-origin: top;
    -webkit-transform-origin: top;
    overflow: hidden;
    -webkit-overflow: hidden;
    filter: blur(4px);
    mix-blend-mode: difference;
    /* transition: transform 0.5s;
    transition-timing-function: ease-out; */
  }
.wrapper {
  position: absolute;
}

.lampBody {
  position: absolute;
  margin: 1vh;
  width: 25vw;
  height: 50vw;
}

.lampHead {
  position: absolute;
  margin: 1vh;
  width: 17vw;
  height: 18vw;
  left: 5.5vw;
  top: 1.2vw;
  transform: rotate(-15deg);
  -webkit-transform: rotate(-15deg);
  transform-origin: top;
  -webkit-transform-origin: top;
  z-index: 4;
  /* transition: transform 1s;
  transition-timing-function: ease-out; */
}
.App {
  position: relative;
  height: 100%;
}



.App-header {
  /* min-height: 100vh; */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: transparent;
  /* mix-blend-mode: difference; */
  position: relative;
  top: 30vh;
  padding: 20px;

  overflow-y: visible;
}

